import React, { FC, useEffect } from 'react';
import * as serviceWorker from './serviceWorkerRegistration';

const ServiceWorkerWrapper: FC = () => {
  const [showReload, setShowReload] = React.useState(false);
  const [
    waitingWorker,
    setWaitingWorker,
  ] = React.useState<ServiceWorker | null>(null);

  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  useEffect(() => {
    serviceWorker.register({ onUpdate: onSWUpdate });
  }, []);

  const reloadPage = () => {
    waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
    setShowReload(false);
    window.location.reload();
  };

  if (showReload) {
    return (
      <div>
        Er is een update beschikbaar!{' '}
        <button onClick={reloadPage}>Klik hier op te updaten</button>
      </div>
    );
  } else {
    return <></>;
  }
};

export default ServiceWorkerWrapper;
